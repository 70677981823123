import { render, staticRenderFns } from "./RemindersToDoReport.vue?vue&type=template&id=53a030fe&scoped=true"
import script from "./RemindersToDoReport.js?vue&type=script&lang=js&external"
export * from "./RemindersToDoReport.js?vue&type=script&lang=js&external"
import style0 from "./RemindersToDoReport.vue?vue&type=style&index=0&id=53a030fe&prod&scoped=true&lang=scss"
import style1 from "./RemindersToDoReport.vue?vue&type=style&index=1&id=53a030fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a030fe",
  null
  
)

export default component.exports