import { filterRulesMapped } from '@/tools/filterRules'
import reminderApi from '@/api/reminder.api'
import i18n from '@/i18n'

export default {
  name: 'RemindersToDoReport',
  components: {
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    loadingTable: false,
    detailTableHeaders: [],
    detailTableFilterRules: {},
    detailTableData: [],
    detailTableDataKey: '_id',
    loadingDetailTable: false,
    loadingSpinner: true,
    disableRefreshTable: true,
    selectedTab: null,
    currentValueData: [],
    nextAlertData: [],
    infoItems: [{
      text: i18n.t('reminder.nextAlert'),
      value: 'nextAlert'
    }, {
      text: i18n.t('reminder.currentValues'),
      value: 'currentValues'
    }],
    selectedInfo: 'currentValues',
    objects: [],
    selectedObject: null,
    matrix: {},
    types: []
  }),
  created () {
  },
  beforeDestroy () {
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.getData()
  },
  methods: {
    /**
     * Acción del botón para actualizar los datos de la tabla
     */
    refreshTable () {
      this.loadingTable = true
      this.getData(true)
    },
    getData (refresh = false) {
      this.loadingTable = true
      reminderApi.getReminderSummary().then(response => {
        this.matrix = response.matrix
        this.types.cleanAndUpdate(response.types.sort((a, b) => a.text < b.text ? -1 : (a.text > b.text ? 1 : 0)))
        this.objects.cleanAndUpdate(response.objects.sort((a, b) => a.text < b.text ? -1 : (a.text > b.text ? 1 : 0)))
        this.currentValueData.cleanAndUpdate(response.currentValueData)
        this.nextAlertData.cleanAndUpdate(response.nextAlertData)
        this.changeTableInfo()
        if (!refresh) {
          this.setTableFilterRules(response.headers)
          this.tableHeaders.cleanAndUpdate(response.headers)
        }
        this.loadingTable = false
      })
    },
    changeTableInfo () {
      switch (this.selectedInfo) {
        case 'nextAlert':
          this.tableData.cleanAndUpdate(this.nextAlertData)
          break
        case 'currentValues':
          this.tableData.cleanAndUpdate(this.currentValueData)
          break
      }
    },
    /**
     * Para setear las reglas de filtrado
     * @param {*} headers
     */
    setTableFilterRules (headers) {
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    setDetailTableFilterRules (headers) {
      headers.forEach(header => {
        this.detailTableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    getDetailData (data, fromSummary = true) {
      this.loadingDetailTable = false
      reminderApi.getReminderDetail(fromSummary ? data._id : data).then(response => {
        this.detailTableData.cleanAndUpdate(response.data)
        this.setDetailTableFilterRules(response.headers)
        this.detailTableHeaders.cleanAndUpdate(response.headers)
        this.loadingTable = false
        this.selectedTab = 2
        if (fromSummary) this.selectedObject = data._id
      })
    },
    selectType (t) {
      this.$set(t, 'selected', !t.selected)
      this.types.forEach(t => { t.show = true })
      this.objects.forEach(o => {
        this.$set(o, 'selected', false)
        let showObject = true
        this.types.filter(t => t.selected).forEach(t => {
          if (!this.matrix[o.value + '-' + t.value]) showObject = false
        })
        o.show = showObject
      })
    },
    selectObject (o) {
      this.$set(o, 'selected', !o.selected)
      this.objects.forEach(o => { o.show = true })
      this.types.forEach(t => {
        this.$set(t, 'selected', false)
        let showType = true
        this.objects.filter(o => o.selected).forEach(o => {
          if (!this.matrix[o.value + '-' + t.value]) showType = false
        })
        t.show = showType
      })
    }
  }
}
